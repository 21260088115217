<template>
  <div class='py-2 lg:py-8 px-4 lg:px-0 flex flex-col lg:flex-row justify-start gap-y-4'>
    <div class='w-full lg:w-48 h-full flex-shrink-0 bg-white lg:top-24 lg:sticky'>
      <h5 class='text-base font-medium'>Contents</h5>
      <ul class='mt-2 px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm'>
        <li>
          <a class='hover:underline cursor-pointer'
            :style='`color: ${eventMainThemeColor};`'
            v-scroll-to="{ 
              el: '#accomodation-swiss-grand-hotel',
              offset: -100
            }">
            Swiss Grand Hotel
          </a>
        </li>
        <li class='mt-1'>
          <a class='hover:underline cursor-pointer'
            :style='`color: ${eventMainThemeColor};`'
            v-scroll-to="{ 
              el: '#accomodation-l7-hongdae-by-Lotte',
              offset: -100
            }">
            L7 Hongdae By Lotte
          </a>
        </li>
      </ul>
    </div>
    <div>
      <h1 class='text-3xl lg:text-4xl font-bold w-full lg:w-1/3'>Accommodations</h1>
      <div class='mt-12 flex flex-col lg:flex-row justify-start items-start'>
        <h2 class='mt-2 text-base lg:text-lg w-full'>
          The Organizing Committee has complied a comprehensive list of hotels for the KOA 2022 participants, ranging from top-scale luxury hotels to more affordable alternatives. Please select a hotel and proceed the reservation directly.
        </h2>
      </div>
      <div class='mt-8 lg:mt-24' id='accomodation-swiss-grand-hotel'>
        <h2 class='text-xl lg:text-2xl font-bold w-full lg:w-1/2'>Swiss Grand Hotel</h2>
        <div class='mt-8 flex flex-row gap-x-4'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/accommodations/hotel_swiss_1.jpg' class='w-5/12'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/accommodations/hotel_swiss_2.jpg' class='w-5/12'>
        </div>
        <div class='mt-4 flex flex-row gap-x-4'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/accommodations/hotel_swiss_3.png' class='w-5/12'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/accommodations/hotel_swiss_4.png' class='w-5/12'>
        </div>
        <div class='mt-8'>
          <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='list-outside mt-2 mb-8'>
              Address: 353 Yeonhui-ro, Seodaemun-gu, Seoul, Korea (Postal Code. 03650)
            </li>
            <li class='list-outside mt-2 mb-8'>
              Website: 
              <a href='http://www.swissgrand.co.kr'
                target='_blank'
                class='underline font-medium'>
                http://www.swissgrand.co.kr
              </a>
            </li>
            <li class='list-outside mt-2 mb-8'>
              Reservation link: 
              <a href='https://bit.ly/3bKmELi'
                target='_blank'
                class='underline font-medium'>
                https://bit.ly/3bKmELi
              </a><br>
              Please click the reservation link above and make your reservation directly.
            </li>
          </ul>
        </div>
      </div>
      <div class='mt-8 lg:mt-24' id='accomodation-l7-hongdae-by-Lotte'>
        <h2 class='text-xl lg:text-2xl font-bold w-full lg:w-1/2'>L7 Hongdae By Lotte</h2>
        <div class='mt-8 flex flex-row gap-x-4'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/accommodations/hotel_l7_1.jpg' class='w-5/12'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/accommodations/hotel_l7_2.jpg' class='w-5/12'>
        </div>
        <div class='mt-4 flex flex-row gap-x-4'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/accommodations/hotel_l7_3.jpg' class='w-5/12'>
          <img src='https://d3r2ol85dktaw0.cloudfront.net/conferences/koa2022/accommodations/hotel_l7_4.jpg' class='w-5/12'>
        </div>
        <div class='mt-8'>
          <ul class='px-4 list-disc list-outside whitespace-pre-line text-gray-600 text-sm lg:text-base leading-6'>
            <li class='list-outside mt-2 mb-8'>
              Address: 109, Mapodaero, Mapo-Gu, Seoul, Republic of Korea
            </li>
            <li class='list-outside mt-2 mb-8'>
              Website: 
              <a href='https://www.lottehotel.com/hongdae-l7/ko.html'
                target='_blank'
                class='underline font-medium'>
                https://www.lottehotel.com/hongdae-l7/ko.html
              </a>
            </li>
            <li class='list-outside mt-2 mb-8'>
              Reservation link: 
              <a href='https://bit.ly/3IPqRtH'
                target='_blank'
                class='underline font-medium'>
                https://bit.ly/3IPqRtH
              </a><br>
              Please click the reservation link above and make your reservation directly.
            </li>
            <li class='list-outside mt-2 mb-8'>
              Reservation code: KOA2022
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Accommodations',
  computed: {
    ...mapGetters('events', [
      'eventMainThemeColor',
    ]),
  }
}
</script>

<style type='scss' scoped>
  .sponsorship-table tr {
    @apply border-b
  }

  .sponsorship-table td {
    @apply border-r p-1 lg:p-2
  }

  .sponsorship-table th {
    @apply border-r p-1 lg:p-2
  }
</style>
